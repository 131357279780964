<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.MercurioSubtitles.Title") }}</h1>
        <form @submit="checkForm">
          <div class="border rounded sections mb-2">
            <h2>{{ $t("Copla.MercurioSubtitles.SessionData.Title") }}</h2>
            <div class="form-group row">
              <label for="session" class="col-2 col-form-label">{{
                $t("Copla.MercurioSubtitles.SessionData.IdSession")
              }}</label>
              <div class="col-10">
                <input
                  type="number"
                  class="form-control"
                  name="session"
                  id="session"
                  min="1"
                  v-model="session"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="group" class="col-2 col-form-label">{{
                $t("Copla.MercurioSubtitles.SessionData.IdGroup")
              }}</label>
              <div class="col-10">
                <input
                  type="number"
                  class="form-control"
                  name="group"
                  id="group"
                  min="1"
                  max="4"
                  v-model="group"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="server" class="col-2 col-form-label">{{
                $t("Copla.MercurioSubtitles.SessionData.Server")
              }}</label>
              <div class="col-10">
                <select v-model="server" class="custom-select">
                  <option selected value="CESyA">{{
                    $t("Copla.MercurioSubtitles.Server.CESyA")
                  }}</option>
                  <option value="GoogleCloud">{{
                    $t("Copla.MercurioSubtitles.Server.GoogleCloud")
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="border a rounded sections mb-2" style="text-align:left;">
            <h2 style="display:inline;margin-right:20px;">
              {{ $t("Copla.MercurioSubtitles.Format.Title") }}
            </h2>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="mode"
                id="srt"
                value="srt"
                checked="checked"
                v-model="type"
              />
              <label class="form-check-label" for="srt">{{
                $t("Copla.MercurioSubtitles.Format.SRT")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="mode"
                id="transcription"
                value="transcription"
                v-model="type"
              />
              <label class="form-check-label" for="transcripcion">{{
                $t("Copla.MercurioSubtitles.Format.Transcription")
              }}</label>
            </div>
            <div v-if="srt">
              <div class="form-group row  mt-4">
                <label for="lines" class="col-2 col-form-label text-center">{{
                  $t("Copla.MercurioSubtitles.Format.Lines")
                }}</label>
                <div class="col-10">
                  <input
                    type="number"
                    class="form-control"
                    name="lines"
                    id="lines"
                    min="1"
                    v-model="lines"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="characters"
                  class="col-2 col-form-label text-center"
                  >{{ $t("Copla.MercurioSubtitles.Format.Characters") }}</label
                >
                <div class="col-10">
                  <input
                    type="number"
                    class="form-control"
                    name="characters"
                    id="characters"
                    min="1"
                    max="120"
                    v-model="characters"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="border rounded sections">
            <h2>{{ $t("Copla.MercurioSubtitles.Dates.Title") }}</h2>
            <div class="form-group row">
              <label for="start" class="col-2 col-form-label text-center">{{
                $t("Copla.MercurioSubtitles.Dates.Start")
              }}</label>
              <div class="col-10">
                <input
                  type="datetime-local"
                  class="form-control"
                  id="start"
                  name="start"
                  v-model="start"
                  required
                />
              </div>
              <label for="end" class="col-2 col-form-label text-center">{{
                $t("Copla.MercurioSubtitles.Dates.End")
              }}</label>
              <div class="col-10">
                <input
                  type="datetime-local"
                  class="form-control"
                  id="end"
                  name="end"
                  v-model="end"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="text-center">
                <button
                  type="submit"
                  id="send"
                  name="send"
                  class="btn btn-success"
                >
                  {{ $t("Copla.MercurioSubtitles.Button") }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="border rounded sections mt-4" id="messages">
          <span v-html="messages"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
export default {
  name: "MercurioSubtitles",
  components: {
    MainMenu
  },
  data() {
    return {
      session: "103",
      group: "1",
      lines: "2",
      characters: "37",
      start: "2020-10-01T11:00",
      end: "2020-10-01T13:00",
      type: "srt",
      server: "CESyA",
      messages: "",
      base_url: process.env.VUE_APP_BASE_URL
    };
  },
  methods: {
    download: function(content) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(content)
      );
      element.setAttribute("download", this.file);
      element.style.display = "none";
      element.click();
    },
    checkForm: function(e) {
      e.preventDefault();
      let self = this;
      this.messages = self.$t("Copla.MercurioSubtitles.Messages.Loading");
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.postMercurio(self.dataAjax, self.type).then(response => {
        if (response.code == "200") {
          self.messages = self.$t("Copla.MercurioSubtitles.Messages.Done");
          self.download(response.data);
        } else {
          self.messages = response.data;
        }
      });
    }
  },
  computed: {
    srt: function() {
      if (this.type == "srt") return true;
      else return false;
    },
    file: function() {
      if (this.type == "srt") return "subtitles.srt";
      else return "transcription.txt";
    },
    dataAjax: function() {
      const data = {
        _session: this.session,
        _group: this.group,
        _lines: this.lines,
        _characters: this.characters,
        _start: this.start,
        _end: this.end,
        _server: this.server
      };
      return data;
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.sections {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
