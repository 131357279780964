import axios from "axios";
export default class formatSubtitlesAPI {
  constructor(data) {
    this.url = "https://formatsubtitles." + data.base_url + "/api/v2/";
    this.token = data.token;
    this.headers = {
      Authorization: "Bearer " + this.token
    };
  }

  async postCAF(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "caf";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postVersion(data, id, version) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "version/" + id + "/" + version;
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getCAFs() {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "caf";
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getVersions(id) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "caf/" + id;
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getSRT(id, version) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "srt/" + id + "/" + version;
    try {
      const response = await axios.get(endPoint, { headers: this.headers });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getTranscription(id, version) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "transcription/" + id + "/" + version;
    try {
      const response = await axios.get(endPoint, { headers: this.headers });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postTranscription(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "transcription";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postSync(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "sync";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getPhrases(id, version) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "phrases/" + id + "/" + version;
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getAudiodescriptions(id) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "audiodescriptions/" + id + "/1";
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getSubtitles(id) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "subtitles/" + id + "/1";
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async getTeatroReal() {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "teatroreal"
    try {
      const response = await axios.get(endPoint, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async deleteCAF(id) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "caf/" + id;
    try {
      const response = await axios.delete(endPoint, { headers: this.headers });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async deleteVersion(id, version) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "version/" + id + "/" + version;
    try {
      const response = await axios.delete(endPoint, { headers: this.headers });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postMercurio(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "mercurio";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postTeatroReal(data) {
    this.headers["Content-Type"] = "multipart/form-data";
    this.headers["Accept"] = "application/json";
    let endPoint = this.url + "teatroreal"
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postADs(data) {
    this.headers["Content-Type"] = "application/json"
    let endPoint = this.url + "synthesis"
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async uploadFile(data) {
    this.headers["Content-Type"] = "multipart/form-data";
    this.headers["Accept"] = "application/json";
    let endPoint = this.url + "files/upload";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postSRT(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "srt";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  async postStartit(data) {
    this.headers["Content-Type"] = "text/plain";
    let endPoint = this.url + "startit";
    try {
      const response = await axios.post(endPoint, data, {
        headers: this.headers
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

}
